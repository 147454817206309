<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import type { Props, DataTrack } from './types'
import './_index.scss'
import { parseFiltersToCriteria, updateCriteriaForExtended, getSpecificCriteria } from './utils'
import AppSlider from '@/components/AppSlider/index.vue'
import { extractListingIdsFromProjectsSummaries } from '@/composables/useOnboarding/utils'
import { convertSearchFiltersToQueryObject } from '@/components/SearchFilters/utils'
import { SCREEN } from '@/utils/segment'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'

const props = withDefaults(defineProps<Props>(), {
  screen: SCREEN.INVESTMENT_QUIZ
})

const { t } = useI18n()
const route = useRoute()

const { $trackEvent } = useNuxtApp()

const COOKIE_EXPIRATION = 60 * 10

const generateUniqueId = (prefix: string): string =>
  `${prefix}-${crypto.randomUUID()}`

const trackListingCollectionViewed = (data: DataTrack, index: number): void => {
  $trackEvent({
    name: 'listing_collection_viewed',
    version: '1-0-0',
    data
  })
}

const searchFiltersStore = useSearchFiltersStore()
const { currentFilters } = storeToRefs(searchFiltersStore)

const trackCollection = (
  type: 'exact' | 'extended' | 'extra',
  filterKey: string | undefined,
  projects: any[],
  index: number,
  description: string
): string | undefined => {
  const filters = convertSearchFiltersToQueryObject(currentFilters.value)
  let collectionCriteria = parseFiltersToCriteria(filters)
  if (filterKey) {
    if (type === 'extended') {
      collectionCriteria = updateCriteriaForExtended(collectionCriteria, filterKey)
    } else if (type === 'extra') {
      collectionCriteria = getSpecificCriteria(collectionCriteria, filterKey, 'not equal')
    }
  }

  if (projects.length) {
    const listings = extractListingIdsFromProjectsSummaries(projects)
    const collectionId = generateUniqueId(`${type}-${filterKey || 'match'}-${index}`)
    const data = {
      collection_id: collectionId,
      description,
      collection_name: `Carousel of ${type}-${filterKey || 'match'}-${index}`,
      screen: props.screen,
      listings,
      collection_criteria: collectionCriteria
    }

    trackListingCollectionViewed(data, index)
    return collectionId
  }

  return undefined
}

const exactCollectionId = ref<string | undefined>()
const extendedCollectionIds = ref<(string | undefined)[]>([])
const extraCollectionIds = ref<(string | undefined)[]>([])

onMounted(() => {
  if (props.exactMatchProjects?.length) {
    exactCollectionId.value = trackCollection(
      'exact',
      undefined,
      props.exactMatchProjects,
      0,
      'Extended search carousel based on the filter.'
    )
  }

  extendedCollectionIds.value = props.extendedSearch.map((carousel, index) =>
    trackCollection(
      'extended',
      carousel.filter,
      carousel.projects,
      index,
      `Extended search carousel based on the filter ${carousel.filter}.`
    )
  )

  extraCollectionIds.value = props.extraResults.map((carousel, index) =>
    trackCollection(
      'extra',
      carousel.filter,
      carousel.projects,
      index,
      `Additional results carousel based on recommendations related to the filters ${carousel.filter}.`
    )
  )
})

const handleCardClick = (cardIndex: number, collectionId: string | undefined, projectCode: string | undefined): void => {
  if (!projectCode) return

  const cookieName = `listingCollection_last_card_${projectCode}`
  const collectionCookie = useCookie(cookieName, { maxAge: COOKIE_EXPIRATION })

  collectionCookie.value = {
    collection_id: collectionId,
    index: cardIndex
  }
}

const getExactBedrooms = (carrouselKey = '') => {
  if (carrouselKey === 'bedrooms_number') return null

  return route.query.bedrooms_number ? parseInt(route.query.bedrooms_number as string) : null
}

const extendedSearchResultsToRender = computed(() => {
  if (!props.extendedSearch.length) return []

  return props.extendedSearch.filter(result => result.projects.length)
})

const extraResultsToRender = computed(() => {
  if (!props.extraResults.length) return []

  return props.extraResults.filter(result => result.projects.length)
})

const carouselsCount = computed(() => ({
  exactMatchProjects: props.exactMatchProjects.length ? 1 : 0,
  extendedSearch: extendedSearchResultsToRender.value.length,
  extraResults: extraResultsToRender.value.length
}))

const carouselsLimits = computed(() => {
  if (!props.carouselsLimit) return carouselsCount.value

  let remainingLimit = props.carouselsLimit

  const exactMatchProjectsLimit = Math.min(carouselsCount.value.exactMatchProjects, remainingLimit)
  remainingLimit -= exactMatchProjectsLimit
  const extendedSearchLimit = Math.min(carouselsCount.value.extendedSearch, remainingLimit)
  remainingLimit -= extendedSearchLimit
  const extraResultsLimit = Math.min(carouselsCount.value.extraResults, remainingLimit)

  return {
    exactMatchProjects: exactMatchProjectsLimit,
    extendedSearch: extendedSearchLimit,
    extraResults: extraResultsLimit
  }
})
</script>

<script lang="ts">
export default {
  name: 'OnboardingResultsCarousels'
}
</script>

<template>
  <div class="onboarding-results-carousel">
    <div v-if="exactMatchProjects?.length">
      <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
        {{ t("exactResultsTitle") }}
        <span>
          ({{ t('projectsNumber', { n: exactMatchProjects?.length }) }})
        </span>
      </h2>

      <div
        v-if="isLoading"
        class="h-440" />

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24"
        :class="{ 'h-480 overflow-hidden': carouselsLimit }">
        <InvestmentProjectCard
          v-for="(project, index) in exactMatchProjects"
          :key="project.code"
          :project="project"
          :site="`${screen}-exact-match-${index}`"
          :exact-bedrooms="(getExactBedrooms() as number)"
          picture-lazy-load
          flexible-card
          clickable />
      </div>
    </div>

    <template
      v-for="(carousel, index) in extendedSearchResultsToRender.slice(0, carouselsLimits.extendedSearch)"
      :key="index">
      <div>
        <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
          {{ t(`extendedSearch.${carousel.filter}`) }}
          <span>
            ({{ t('projectsNumber', { n: carousel.projects.length }) }})
          </span>
        </h2>

        <div
          v-if="isLoading"
          class="h-440" />

        <AppSlider
          v-if="!isLoading"
          :id="`onboarding-extended-search-${carousel.filter}-results-carousel`"
          lateral-paddings="hidden"
          shadow-height="440px"
          :slider-scroll="400"
          show-buttons>
          <InvestmentProjectCard
            v-for="(project, i) in carousel.projects"
            :key="project.code"
            :project="project"
            :site="`${screen}-${carousel.filter}-${i}`"
            :exact-bedrooms="(getExactBedrooms(carousel.filter) as number)"
            picture-lazy-load
            clickable
            @card-click="handleCardClick(i, extendedCollectionIds[index], project.code)" />
        </AppSlider>
      </div>
    </template>

    <template
      v-for="(carousel, index) in extraResultsToRender.slice(0, carouselsLimits.extraResults)"
      :key="index">
      <div>
        <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
          {{ t(`extraResults.${carousel.filter}`) }}
          <span>
            ({{ t('projectsNumber', { n: carousel.projects.length }) }})
          </span>
        </h2>

        <div
          v-if="isLoading"
          class="h-440" />

        <AppSlider
          v-if="!isLoading"
          :id="`onboarding-${carousel.filter}-extra-results-carousel`"
          lateral-paddings="hidden"
          shadow-height="440px"
          :slider-scroll="400"
          show-buttons>
          <InvestmentProjectCard
            v-for="(project, elIndex) in carousel.projects"
            :key="project.code"
            :project="project"
            :site="`${screen}-extra-results-${elIndex}`"
            picture-lazy-load
            clickable
            @card-click="handleCardClick(elIndex,extraCollectionIds[index], project.code )" />
        </AppSlider>
      </div>
    </template>
  </div>
</template>

<i18n src="./i18n.json" />
