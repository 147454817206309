import type { FilterCriteria } from './types'

export const parseFiltersToCriteria = (filters: Record<string, string | undefined>): FilterCriteria[] =>
  Object.entries(filters)
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => {
      const operator = value!.includes('~') ? 'in' : value!.includes(':') ? 'between' : 'equal'
      return { field, value: value!, operator }
    })

export const updateCriteriaForExtended = (
  criteria: FilterCriteria[],
  filterKey: string
): FilterCriteria[] =>
  criteria.map(criterion => ({
    ...criterion,
    operator: criterion.field === filterKey ? 'not equal' : criterion.operator
  }))

export const getSpecificCriteria = (
  criteria: FilterCriteria[],
  filterKey: string,
  newOperator: string
): FilterCriteria[] =>
  criteria
    .filter(criterion => criterion.field === filterKey)
    .map(criterion => ({ ...criterion, operator: newOperator }))
